<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumbDetail">
      <el-button type="primary" @click="gobackDetail">返回</el-button>
    </div>

    <!--主体内容区-->
    <div class="content">
      <div class="depoErrorDetail">
        <el-form label-position="left" :model="formInline" :ref="'form'">
          <div style="border: 1px solid #C0CCDA;border-radius: 4px;padding:15px;">
            <h2 class="title">
              <div class="title_icon"></div>
              <span>基础信息</span>
            </h2>
            <div style="width: 800px;padding-left:20px;">
              <el-form-item label="道路/车场:">
                <span>{{formInline.parkName}}</span>
              </el-form-item>
              <el-form-item label="道路/车场编码:">
                <span>{{formInline.parkCode}}</span>
              </el-form-item>
              <el-form-item label="道路/车场物理类型:">
                <span>{{formInline.parkTypeDesc}}</span>
              </el-form-item>
              <el-form-item label="区域:">
                <span>{{formInline.areaName}}</span>
              </el-form-item>
              <el-form-item label="车场状态:">
                <span>{{formInline.parkStateDesc}}</span>
              </el-form-item>
            </div>
          </div>
          <div style="border: 1px solid #C0CCDA;border-radius: 4px;padding:15px; margin-top: 20px;">
            <h2 class="title">
              <div class="title_icon"></div>
              <span>纠错详情</span>
            </h2>
            <div style="width: 800px;padding-left:20px;">
              <el-form-item label="报错类型:">
                <span>{{formInline.correctionTypeDesc}}</span>
              </el-form-item>
              <el-form-item label="问题描述:">
                <span>{{formInline.description}}</span>
              </el-form-item>
              <div>
                <span class="span_pic">图片：</span>
                <!-- <img
                  :src="vlaue"
                  alt="Smiley face"
                  class="pic"
                  v-for="(vlaue,index) in formInline.pictureUrl"
                  :key="index"
                /> -->
                <div class="pic" v-for="(vlaue,index) in formInline.pictureUrl"
                  :key="index">
                  <el-image
                    style="height: 240px"
                    :src="vlaue"
                    :preview-src-list="[vlaue]">
                  </el-image>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "depoErrorDetail",
  data() {
    return {
      formInline: {},
    };
  },
  components: {},
  created() {
    this.searParkRecordDetail(this.$route.query.correctionRecordId);
  },
  mounted() {},
  methods: {
    gobackDetail() {
      this.$router.replace({path: "/errorCorrection"})
    },
    searParkRecordDetail(item) {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkCorrectionRecord/query",
        data: {
          correctionRecordId: item,
        },
        success: (res) => {
          if (res.state === 0) {
            this.formInline = res.value;
            // console.log("res.value.total", res.value.total);
            // this.total = parseInt(res.value.total);
          }
        },
        fail: () => {
          // this.loading = false;
        },
      };
      this.$request(opt);
    },
  },
};
</script><!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumbDetail {
  text-align: right;
  height: 55px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;
}

#addTicket {
  cursor: pointer;
  font-size: 40px;
  color: #0f6eff;
  display: inline-block;
  vertical-align: top;
  margin-top: -3px;
}

.marginSty {
  margin-bottom: 20px;
}

.span_pic {
  color: #606266;
}

.pic {
  display: inline-block;
  width: auto;
  height: 240px;
  vertical-align: top;
  margin-right 20px;
  border: 1px solid #eee;
}
</style>
<style>
.depoErrorDetail .el-form-item {
  margin-bottom: 0px;
}
</style>
