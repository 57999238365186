var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c(
      "div",
      { staticClass: "breadcrumbDetail" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.gobackDetail } },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "depoErrorDetail" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { "label-position": "left", model: _vm.formInline },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    border: "1px solid #C0CCDA",
                    "border-radius": "4px",
                    padding: "15px",
                  },
                },
                [
                  _c("h2", { staticClass: "title" }, [
                    _c("div", { staticClass: "title_icon" }),
                    _c("span", [_vm._v("基础信息")]),
                  ]),
                  _c(
                    "div",
                    { staticStyle: { width: "800px", "padding-left": "20px" } },
                    [
                      _c("el-form-item", { attrs: { label: "道路/车场:" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.formInline.parkName))]),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "道路/车场编码:" } },
                        [_c("span", [_vm._v(_vm._s(_vm.formInline.parkCode))])]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "道路/车场物理类型:" } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formInline.parkTypeDesc)),
                          ]),
                        ]
                      ),
                      _c("el-form-item", { attrs: { label: "区域:" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.formInline.areaName))]),
                      ]),
                      _c("el-form-item", { attrs: { label: "车场状态:" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.formInline.parkStateDesc)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    border: "1px solid #C0CCDA",
                    "border-radius": "4px",
                    padding: "15px",
                    "margin-top": "20px",
                  },
                },
                [
                  _c("h2", { staticClass: "title" }, [
                    _c("div", { staticClass: "title_icon" }),
                    _c("span", [_vm._v("纠错详情")]),
                  ]),
                  _c(
                    "div",
                    { staticStyle: { width: "800px", "padding-left": "20px" } },
                    [
                      _c("el-form-item", { attrs: { label: "报错类型:" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.formInline.correctionTypeDesc)),
                        ]),
                      ]),
                      _c("el-form-item", { attrs: { label: "问题描述:" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.formInline.description)),
                        ]),
                      ]),
                      _c(
                        "div",
                        [
                          _c("span", { staticClass: "span_pic" }, [
                            _vm._v("图片："),
                          ]),
                          _vm._l(
                            _vm.formInline.pictureUrl,
                            function (vlaue, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "pic" },
                                [
                                  _c("el-image", {
                                    staticStyle: { height: "240px" },
                                    attrs: {
                                      src: vlaue,
                                      "preview-src-list": [vlaue],
                                    },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }